<ng-container>
  <section [class.full-width]="fullWidth" [class.full-height]="fullHeight" class="screen-container">
    <header *ngIf="!loading">
      <img *ngIf="!systemService.isMobile" src="{{ imageUrl }}" alt="" />
      <p class="title">{{ title }}</p>
      <p class="subtitle" [innerHTML]="subtitle"></p>
    </header>

    <ng-container *ngIf="loading; else view">
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <ng-template #view>
      <div class="screen-body">
        <div [class.no-card]="!useCard" class="card-container">
          <mat-card class="screen-card mat-elevation">
            <ng-content></ng-content>
          </mat-card>
        </div>
        <p *ngIf="disclaimer" class="disclaimer">
          {{ disclaimer }}
        </p>
        <div class="action-container">
          <ng-content select="[slot=payment]"></ng-content>
          <button [disabled]="buttonDisabled" (click)="action(false)" oxButton [status]="actionStatus">
            {{ actionName }}
          </button>
          <button
            class="second-action"
            *ngIf="hasSecondAction"
            (click)="action(true)"
            oxButton
            [status]="secondActionStatus"
            [disabled]="secondButtonDisabled"
          >
            {{ secondActionName }}
          </button>
        </div>
      </div>
    </ng-template>
  </section>
</ng-container>
